<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .save-btn.btn-success {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #34c38f;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

const humanizeDuration = require("humanize-duration");

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer'
    ]),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getConfiguration: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/strings`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.configuration = data.configuration;
          this.isSubscriber = data.is_subscriber;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    /* ************************** */
    async saveConfiguration() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        /* *************** */
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          configuration: this.configuration
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/strings`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.configuration.save.success.title'),
            text: this.$t('server.view.configuration.save.success.text'),
          });
          this.configurationChanged = null;
          await this.getConfiguration();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
	  scrollHandler() {
			if(!this.$refs.floatingContainer) return;

		  if(window.pageYOffset > 70) {
			  if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
				  this.$refs.floatingContainer.classList.add('c-floating-side');
			  }
		  } else {
			  this.$refs.floatingContainer.classList.remove('c-floating-side');
		  }
	  }
  },
  created() {
    this.server = this.getServer(this.server_id);
  },
  mounted() {
    this.getConfiguration();

	  window.addEventListener('scroll', this.scrollHandler);
  },
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
	watch: {
    configuration: {
      deep: true,
      handler() {
        if(!this.ready) return;
        if(this.configurationChanged === null) {
          this.configurationChanged = false;
        } else this.configurationChanged = true;
      }
    }
  },
  data() {
    return {
      Game:Game,
      OmegaAction:OmegaAction,
      ready: false,
      error: false,
      inProgress: false,
      server: null,
      isSubscriber: false,
      configurationChanged: null,
      configuration: {

      },
      // Special configs
      special: {

      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-sm-12 d-lg-none">
          <button class="btn btn-block hard-border save-btn"
                  v-on:click="saveConfiguration()"
                  :disabled="inProgress"
                  :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
          >
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.view.configuration.save.button') }}
            </template>
          </button>
          <div class="text-center small text-muted" v-if="configurationChanged === true">
            {{ $t('server.view.configuration.save.info') }}
          </div>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <div class="card hard-border ">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
              {{$t('server.view.configuration.placeholders.message')}}
              <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
                <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12">
          <div>
            <div role="tablist">
              <b-card no-body class="settings-collapse hard-border" v-for="(string, key) in configuration.strings" :key="key">
                <a v-b-toggle="key" class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h4 class="m-0 d-inline-flex hide-sm">
                      {{ $t(`server.view.strings.${key}`) }}
                    </h4>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.configuration.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse :id="key" accordion="strings" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          <b-input
                              v-model="configuration.strings[key]"
                              :readonly="isSubscriber" size="lg"
                              type="text"
                              class="form-control"
                          />
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 hide-sm">
	        <div class="" ref="floatingContainer">
	          <button class="btn btn-block hard-border save-btn"
	                  v-on:click="saveConfiguration()"
	                  :disabled="inProgress"
	                  :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
	          >
	            <half-circle-spinner
	                v-if="inProgress"
	                :animation-duration="1200"
	                :size="16"
	                class="align-middle d-inline-block"
	            />
	            <template v-if="!inProgress">
	              {{ $t('server.view.configuration.save.button') }}
	            </template>
	          </button>
	          <div class="text-center small text-muted" v-if="configurationChanged === true">
	            {{ $t('server.view.configuration.save.info') }}
	          </div>

	          <div class="row mt-2">
	            <div class="col-lg-12">
	              <div class="card">
	                <div class="card-body">
	                  <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
	                  {{$t('server.view.configuration.placeholders.message')}}
	                  <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
	                    <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
